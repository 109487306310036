"use client";
import { reaction } from "mobx";

// import { firestore } from "../firebase";

export default class Deploy {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    let unsubscribe;

    reaction(
      () => (this.mounted ? this._.user.uid : undefined),
      uid => {
        unsubscribe?.();

        if (uid) {
          unsubscribe = this._.user.data.query(
            { collection: "containerEvents" },
            ({ docs }) => {
              const map = {};

              for (const doc of docs) {
                const {
                  eventName,
                  props: { modelId, source, created }
                } = doc.data();

                map[modelId] ??= { modelId };
                map[modelId].source ??= source;

                if (eventName === "ModelInference") {
                  map[modelId].reqs ??= [];
                  map[modelId].reqs.push(created.toDate().getTime());
                }
              }

              this.set.models(Object.values(map));
            }
          );

          // unsubscribe = firestore
          //   .collection("users/wRlI0sMUfKYhMjvF5E2GRs38JKG3/containerEvents")
          //   .watch(({ docs }) => {
          //     const map = {};

          //     for (const doc of docs) {
          //       const {
          //         eventName,
          //         props: { modelId, source, created }
          //       } = doc.data();

          //       map[modelId] ??= { modelId };
          //       map[modelId].source ??= source;

          //       if (eventName === "ModelInference") {
          //         map[modelId].reqs ??= [];
          //         map[modelId].reqs.push(created.toDate().getTime());
          //       }
          //     }

          //     this.set.models(Object.values(map));
          //   });
        }
      }
    );
  }
  set = {
    mounted: (mounted = false) => {
      this.mounted = mounted;
    },
    models: (models = []) => {
      this.models = models;
    }
  };
}
