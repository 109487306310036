export default class Videos {
  constructor(makeMobxStore) {
    this.reset = makeMobxStore(this);
  }
  set = {
    playing: playing => {
      this.playing = playing;
    }
  };
}
