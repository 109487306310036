import { reaction } from "mobx";

export default class Snackbar {
  constructor(makeMobxStore) {
    this.reset = makeMobxStore(this);

    reaction(
      () => [this.open, this.message, this.queue.size],
      ([open, message]) => {
        if (open === false && message === undefined) {
          const [messageKey, message] = this.queue.entries().next().value || [];

          if (message) {
            const queue = new Map(this.queue);

            queue.delete(messageKey);
            this.set.queue(queue);
          }

          this.set.message(message);
          this.set.open(message !== undefined);
        }
      }
    );
  }

  set = {
    open: (open = false) => {
      this.open = open;
    },
    message: message => {
      this.message = message;
    },
    queue: (queue = new Map()) => {
      this.queue = queue;
    }
  };
  notify = (message = {}) => {
    const queue = new Map(this.queue);

    message.key ??= message?.text;
    queue.set(message.key, message);

    this.set.queue(queue);
  };
}
