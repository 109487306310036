import { transaction } from "mobx";

export default class Dialog {
  constructor(makeMobxStore) {
    this.reset = makeMobxStore(this);
  }
  set = {
    headline: headline => {
      this.headline = headline;
    },
    headlineActions: (headlineActions = []) => {
      this.headlineActions = headlineActions;
    },
    BackIconCallback: BackIconCallback => {
      this.BackIconCallback = BackIconCallback;
    },
    Content: (Content = () => null) => {
      this.Content = Content;
    },
    state: (state = {}) => {
      this.state = state;
    },
    fullScreen: (fullScreen = false) => {
      this.fullScreen = fullScreen;
    },
    open: (open = false) => {
      this.open = open;
    },
    props: props => {
      this.props = props;
    },
    sx: (sx = {}) => {
      this.sx = sx;
    }
  };
  toggle = () => this.set.open(!this.open);
  configure = (dialog = {}, open = true) => {
    transaction(() => {
      this.reset();
      //
      for (const setting in dialog) {
        this.set[setting](dialog[setting]);
      }

      this.set.open(open);
    });
  };
}
