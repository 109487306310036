export default class AppBar {
  constructor(makeMobxStore) {
    this.reset = makeMobxStore(this);
  }
  set = {
    Component: Component => {
      this.Component = Component;
    },
    props: (props = {}) => {
      this.props = props;
    },
    fullWidth: (fullWidth = false) => {
      this.fullWidth = fullWidth;
    }
  };
  configure = (config = {}) => {
    this.reset();
    //
    for (const setting in config) {
      this.set[setting](config[setting]);
    }
  };
  get showing() {
    return this.Component !== undefined;
  }
}
