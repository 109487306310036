import { reaction } from "mobx";
import { graphQuery } from "../graph";

export default class Tasks {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);
    //
    reaction(
      () => this._.user.loaded,
      loaded => {
        if (loaded) {
          graphQuery({ query: this._.gql.get("tasks") }).then(({ data }) =>
            this.set.list(data?.tags)
          );
        }
      }
    );

    this.url = Tasks._url;
  }

  set = {
    list: (list = []) => {
      this.list = list;
    }
  };

  static _url = {
    encode: task => task?.replace(/ /g, "_"),
    decode: task => task?.replace(/_/g, " "),
    route: task => `/task/${this._url.encode(task)}`
  };
  get _set() {
    return new Set(this.list.map(tag => tag.name));
  }
  get array() {
    return [...this._set];
  }
}
