import { initializeApp } from "firebase/app";
import {
  initializeFirestore,
  getFirestore,
  collectionGroup,
  collection,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  increment,
  deleteDoc,
  deleteField,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  startAt,
  FieldPath
} from "firebase/firestore";
import {
  getAuth,
  OAuthProvider,
  linkWithCredential,
  signInWithCustomToken,
  onAuthStateChanged,
  signInAnonymously,
  GoogleAuthProvider,
  signInWithPopup,
  signOut
} from "firebase/auth";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { getStorage, ref, listAll } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
});

initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });

const db = getFirestore();
// this initializes analytics, if we want to fire off custom events to firebase
// we can do that with logEvent
const analytics =
  typeof window === "undefined" ? () => {} : getAnalytics(firebaseApp);

class firestoreClass {
  collection(collectionPath) {
    return new this._collection(collectionPath);
  }
  _collection = class Collection {
    constructor(collectionPath) {
      this.collection = collection(db, collectionPath);

      return this;
    }
    add(data) {
      return addDoc(this.collection, data);
    }
    get() {
      return getDocs(this.collection);
    }
    query(options) {
      const clauses = [];

      if (options.where) {
        for (const [field, operator, value] of options.where) {
          clauses.push(where(field, operator, value));
        }
      }
      if (options.limit) {
        clauses.push(limit(options.limit));
      }
      if (options.orderBy) {
        for (const [field, direction] of options.orderBy) {
          clauses.push(orderBy(field, direction));
        }
      }
      if (options.startAfter) {
        clauses.push(startAfter(options.startAfter));
      }
      if (options.startAt) {
        clauses.push(startAt(options.startAt));
      }

      this.collection = query(this.collection, ...clauses);
      delete this.add;
      return this;
    }
    watch(callback) {
      return onSnapshot(this.collection, callback);
    }
  };
  collectionGroup(collectionPath) {
    return new this._collectionGroup(collectionPath);
  }
  _collectionGroup = class CollectionGroup extends this._collection {
    constructor(collectionGroupPath) {
      super(collectionGroupPath);
      this.collection = collectionGroup(db, collectionGroupPath);

      return this;
    }
  };
  doc(collectionPath, docId) {
    return new this._doc(collectionPath, docId);
  }
  _doc = class Doc {
    constructor(collectionPath, docId) {
      this.doc = doc(db, collectionPath, docId);

      return this;
    }
    get() {
      return getDoc(this.doc);
    }
    update(data) {
      return updateDoc(this.doc, data);
    }
    set(data, merge = true) {
      return setDoc(this.doc, data, { merge });
    }
    delete() {
      return deleteDoc(this.doc);
    }
    watch(callback) {
      return onSnapshot(this.doc, callback);
    }
  };
  // firestore
  FieldPath = (...path) => new FieldPath(...path);
  arrayRemove = arrayRemove;
  arrayUnion = arrayUnion;
  increment = increment;
  deleteField = deleteField;
}

export const firestore = new firestoreClass();

const Auth = getAuth(firebaseApp);

export const auth = {
  Auth,
  OAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  onAuthStateChanged: callback => onAuthStateChanged(Auth, callback),
  signOut: () => signOut(Auth),
  signInAnonymously: () => signInAnonymously(Auth),
  signInWithPopup: provider => signInWithPopup(Auth, provider),
  signInWithCustomToken: customToken => signInWithCustomToken(Auth, customToken)
};
export const firebaseAnalytics = {
  track:
    process.env.NODE_ENV === "development"
      ? () => {}
      : (eventName, props) => logEvent(analytics, eventName, props),
  setUserProfile:
    process.env.NODE_ENV === "development"
      ? () => {}
      : profile => setUserProperties(analytics, profile)
};
export const storage = {
  getStorage: getStorage(undefined, "gs://cdn.bytez.com"),
  ref,
  listAll
};
